<template>
  <div id="appCapsule">
    <div class="section wallet-card-section">
      <div class="card">
        <div class="col-12 text-primary py-2">
          All Deposits
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Ref</th>
              <th scope="col">User</th>
              <th scope="col">Status</th>
              <th scope="col">Crypto Amount</th>
              <th scope="col">Fiat Amount</th>
              <th scope="col">Date created</th>
              <th scope="col" class="text-end">Actions</th>
            </tr>
            </thead>
            <tbody>
            <template v-if="getAllReceivedPayments.length > 0">
              <tr v-for="(payment, idx) in getAllReceivedPayments" :key="idx">
                <th scope="row">{{idx+1}}</th>
                <td>{{payment.data.code||payment.id}}</td>
                <td>
                  <template v-if="payment_users[idx]!==undefined">{{payment_users[idx].data.name || payment.data.user}}</template>
                  <template v-else>{{payment.data.user}}</template>
                </td>
                <td>
                                    <span class="badge badge-success" v-if="payment.data.api_response_status === 'CONFIRMED'">
                                        {{payment.data.api_response_status}}
                                    </span>
                  <span class="badge badge-danger" v-else-if="payment.data.api_response_status === 'FAILED'">
                                        {{payment.data.api_response_status}}
                                    </span>
                  <span class="badge badge-warning" v-else-if="payment.data.api_response_status === 'PENDING' || payment.data.api_response_status === 'CREATED'">
                                        {{payment.data.api_response_status}}
                                    </span>
                  <span class="badge badge-primary" v-else>
                                        {{payment.data.api_response_status}}
                                    </span>
                </td>
                <td>{{payment.data.crypto_value}} {{payment.data.crypto}}</td>
                <td>{{payment.data.currency_value}} {{payment.data.currency}}</td>
                <td>{{getReadableDatetime(payment.data.created_at)}}</td>
                <td>
                  <div class="row justify-content-around">
                    <ion-icon @click="active_payload=payment" data-bs-toggle="modal" data-bs-target="#viewDepositInfo" class="text-primary fw-20" name="eye-outline"></ion-icon>
                    <ion-icon v-if="!payment.data.completed&&payment.data.paid_through==='MTW'" data-bs-toggle="modal" data-bs-target="#confirmAmount" @click="active_payload=payment" name="checkmark-done-outline" class="text-warning fw-20"></ion-icon>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="text-center text-primary" colspan="7">
                  <h5>Empty deposit history</h5>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <!-- Modals -->
    <view-deposit-info :payment="active_payload"/>
    <div class="modal fade modalbox" id="confirmAmount" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm Payment
            </h5>
            <a href="javascript:void(0);" data-bs-dismiss="modal">Close</a>
          </div>
          <div class="modal-body">
            <h4 class="col-12 text-center text-primary">
              {{active_payload.data.address}}
            </h4>
            <div class="form-group">
              <label class="form-label">Enter the crypto amount received to this address</label>
              <input type="text" v-model.number="amount_received" class="form-control" :placeholder="'0.00 '+active_payload.data.crypto">
            </div>
            <div class="form-group mt-2">
              <label class="form-label">Enter the equivalent fiat amount received to this address</label>
              <input type="text" v-model.number="fiat_amount_received" class="form-control" :placeholder="'0.00 '+active_payload.data.currency">
            </div>
            <div class="row mt-3">
              <button class="btn btn-primary" data-bs-dismiss="modal" @click="complete_transaction">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import methodsMixin from "../../../utils/methodsMixin";
import viewDepositInfo from "../../../components/admin/modals/viewDepositInfo";

export default {
  name: "deposit",
  data(){
    return {
      active_payload: {id: '', data: {}},
      amount_received: '',
      fiat_amount_received: '',
      payment_users: [],
      interval_val: null
    }
  },
  mixins: [methodsMixin],
  watch: {

  },
  methods: {
    complete_transaction(){
      if(typeof this.amount_received === 'number' && typeof this.fiat_amount_received === 'number'){
        this.$store.dispatch('notification/display_noti', {
          message: 'Confirm this payment? <br> This operation can not be reversed!',
          context: 'icon',
          buttons: [
            {
              name: 'Proceed',
              callback: async ()=>{
                this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                let response = await this.$store.dispatch('transaction/confirmManualPayment', {
                  payment: this.active_payload,
                  amount: this.amount_received,
                  fiat_amount: this.fiat_amount_received
                });
                if(response.status){
                  this.amount_received = this.fiat_amount_received = '';
                  return this.$store.dispatch('notification/display_noti', {
                    message: 'Payment confirmed!',
                    timeout: 1500
                  });
                }else{
                  return this.$store.dispatch('notification/display_noti', {
                    message: response.message,
                  });
                }
              }
            },
            {
              name: 'Cancel',
              callback: ()=>this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
            }
          ]
        });
      }
    },
    async fetchMetaInfo(){
      const usersPromises = this.getAllReceivedPayments.map(payment=>{
        return this.$store.dispatch('user/getUser', payment.data.user);
      });
      let users = await Promise.all(usersPromises);
      this.payment_users = users.map(userResponse=>{
        if(userResponse.status){
          return userResponse.data
        }
        return {id: '', data: {}}
      });
    }
  },
  computed: {
    ...mapGetters('transaction', [
      'getAllReceivedPayments'
    ])
  },
  mounted() {
    this.$store.dispatch('transaction/fetchAllReceivedPayments', this.fetchMetaInfo);
  },
  components: {
    viewDepositInfo
  }
}
</script>

<style scoped>

</style>