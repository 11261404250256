<template>
  <div class="modal fade modalbox" id="viewDepositInfo" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            DEPOSIT-#{{payment.data.code||payment.id.substr(0,10)}}
            <span class="text-end">
                            <small class="badge badge-success" v-if="payment.data.completed">completed transaction</small>
                            <small class="badge badge-warning" v-else>incomplete transaction</small>
                        </span>
          </h5>
          <a href="javascript:void(0);" data-bs-dismiss="modal">Close</a>
        </div>
        <div class="modal-body">
          <p class="text text-dark">
            Payment REF.
            <span class="float-end">
                                {{payment.data.code||payment.id}}
                            </span>
          </p>
          <p class="text text-dark">
            User
            <span class="float-end">
                                {{user.data.name}}
                            </span>
          </p>
          <p class="text text-primary">
            Amount [crypto]
            <span class="float-end">
                                {{payment.data.crypto_value}}
                            {{payment.data.crypto}}
                            </span>
          </p>
          <p class="text text-primary">
            Amount [fiat]
            <span class="float-end">
                                {{payment.data.currency_value}}
                                {{payment.data.currency}}
                        </span>
          </p>
          <p class="text text-primary">
            Payment to be made through
            <span class="float-end">
                                {{payment.data.paid_through}}
                        </span>
          </p>
          <p class="text text-info">
            Wallet
          </p>
          <p class="text text-info">
            {{payment.data.address}}
          </p>

          <div class="row justify-content-center" v-if="payment.data.api_response_status==='FAILED'&&payment.data.paid_through==='MERCHANT'">
            <div class="col-8">
              <button class="btn btn-outline-warning" @click.prevent="override_auto_deposit">
                Confirm this Payment (resolve)
              </button>
            </div>
          </div>

          <p class="text text-dark mt-3">
            Detected Payments (merchant only)
          </p>
          <template v-if="payment.data.api_webhook">
            <template v-if="Object.entries(payment.data.api_webhook.pending).length > 0">
              <h5 class="mt-3">ENTRIES <span class="badge badge-warning">PENDING</span></h5>
              <table class="table table-responsive-md table-striped">
                <thead>
                <tr>
                  <td>S/N</td>
                  <td>Status</td>
                  <td>Network</td>
                  <td>Amount (crypto)</td>
                </tr>
                </thead>
                <tbody v-if="payment.data.api_webhook.pending.event.data.payments.length > 0">
                <tr v-for="(n_payment, idx) in payment.data.api_webhook.pending.event.data.payments" :key="'payment_pending_'+idx">
                  <td>{{idx+1}}</td>
                  <td><span class="badge badge-dark">{{n_payment.status}}</span></td>
                  <td>{{n_payment.network}}</td>
                  <td>{{n_payment.value.crypto.amount}}</td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="text-center" colspan="4">Empty Entry History</td>
                </tr>
                </tbody>
              </table>
            </template>
            <template v-if="Object.entries(payment.data.api_webhook.confirmed).length > 0">
              <h5 class="mt-3">ENTRIES <span class="badge badge-success">CONFIRMED</span></h5>
              <table class="table table-responsive-md table-striped">
                <thead>
                <tr>
                  <td>S/N</td>
                  <td>Status</td>
                  <td>Network</td>
                  <td>Amount (crypto)</td>
                </tr>
                </thead>
                <tbody v-if="payment.data.api_webhook.confirmed.event.data.payments.length > 0">
                <tr v-for="(n_payment, idx) in payment.data.api_webhook.confirmed.event.data.payments" :key="'payment_'+idx">
                  <td>{{idx+1}}</td>
                  <td><span class="badge badge-dark">{{n_payment.status}}</span></td>
                  <td>{{n_payment.network}}</td>
                  <td>{{n_payment.value.crypto.amount}}</td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="text-center" colspan="4">Empty Entry History</td>
                </tr>
                </tbody>
              </table>
            </template>
            <template v-if="Object.entries(payment.data.api_webhook.created).length > 0">
              <h5 class="mt-3">ENTRIES <span class="badge badge-info">CREATED</span></h5>
              <table class="table table-responsive-md table-striped">
                <thead>
                <tr>
                  <td>S/N</td>
                  <td>Status</td>
                  <td>Network</td>
                  <td>Amount (crypto)</td>
                </tr>
                </thead>
                <tbody v-if="payment.data.api_webhook.created.event.data.payments.length > 0">
                <tr v-for="(n_payment, idx) in payment.data.api_webhook.created.event.data.payments" :key="'payment_created_'+idx">
                  <td>{{idx+1}}</td>
                  <td><span class="badge badge-dark">{{n_payment.status}}</span></td>
                  <td>{{n_payment.network}}</td>
                  <td>{{n_payment.value.crypto.amount}}</td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="text-center" colspan="4">Empty Entry History</td>
                </tr>
                </tbody>
              </table>
            </template>
            <template v-if="Object.entries(payment.data.api_webhook.delayed).length > 0">
              <h5 class="mt-3">ENTRIES <span class="badge badge-warning">DELAYED</span></h5>
              <table class="table table-responsive-md table-striped">
                <thead>
                <tr>
                  <td>S/N</td>
                  <td>Status</td>
                  <td>Network</td>
                  <td>Amount (crypto)</td>
                </tr>
                </thead>
                <tbody v-if="payment.data.api_webhook.delayed.event.data.payments.length > 0">
                <tr v-for="(n_payment, idx) in payment.data.api_webhook.delayed.event.data.payments" :key="'payment_delayed_'+idx">
                  <td>{{idx+1}}</td>
                  <td><span class="badge badge-dark">{{n_payment.status}}</span></td>
                  <td>{{n_payment.network}}</td>
                  <td>{{n_payment.value.crypto.amount}}</td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="text-center" colspan="4">Empty Entry History</td>
                </tr>
                </tbody>
              </table>
            </template>
            <template v-if="Object.entries(payment.data.api_webhook.failed).length > 0">
              <h5 class="mt-3">ENTRIES <span class="badge badge-danger">FAILED</span></h5>
              <table class="table table-responsive-md table-striped">
                <thead>
                <tr>
                  <td>S/N</td>
                  <td>Status</td>
                  <td>Network</td>
                  <td>Amount (crypto)</td>
                </tr>
                </thead>
                <tbody v-if="payment.data.api_webhook.failed.event.data.payments.length > 0">
                <tr v-for="(n_payment, idx) in payment.data.api_webhook.failed.event.data.payments" :key="'payment_failed_'+idx">
                  <td>{{idx+1}}</td>
                  <td><span class="badge badge-dark">{{n_payment.status}}</span></td>
                  <td>{{n_payment.network}}</td>
                  <td>{{n_payment.value.crypto.amount}}</td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="text-center" colspan="4">Empty Entry History</td>
                </tr>
                </tbody>
              </table>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "viewDepositInfo",
  data(){
    return {
      user: {id: '', data: {}}
    }
  },
  props: {
    payment: {
      type: Object,
      required: true,
      default: ()=>{
        return {id: '', data: {}}
      }
    }
  },
  methods: {
    override_auto_deposit(){
      this.$store.dispatch('notification/display_noti', {
        message: 'You are about to confirm this deposit. <br> This operation can not be reversed and we sure hope you know what you are doing!',
        context: 'icon',
        buttons: [
          {
            name: 'Continue',
            callback: async ()=>{
              this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
              this.$loader.show()
              let response = await this.$store.dispatch('transaction/overrideAutomaticPayment', {
                payment: this.payment,
              });
              this.$loader.hide()
              if(response.status){
                // this.amount = ''; this.selected_crypto = 'BTC';
                $('#viewDepositInfo').modal('hide');
                return this.$store.dispatch('notification/display_noti', {
                  message: 'Operation successful!',
                  timeout: 1500
                });
              }else{
                return this.$store.dispatch('notification/display_noti', {
                  message: response.message,
                });
              }
            }
          },
          {
            name: 'Cancel',
            callback: ()=>this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
          }
        ]
      });
    }
  },
  mounted() {
    const modal = $('#viewDepositInfo');
    modal.on('show.bs.modal', async ()=>{
      this.user = {id: '', data: {}};
      setTimeout(async()=>{
        let response = await this.$store.dispatch('user/getUser', this.payment.data.user);
        if(response.status){
          this.user = response.data;
        }
      }, 1000)
    });
  }
}
</script>

<style scoped>

</style>